<template>
  <div style="padding-top: 0; margin-top: -20px;">
    <transition name="fade" appear>
      <v-col style="min-height:300px;padding-top: 0" cols="12" :style="liningStyles">
        <v-row class="mt-5">
          <v-col cols="12">
            <v-row class="header">
              <transition name="fade" appear>
                <v-col
                    cols="12"
                >
                  <div>
                    <h3 class="text-center mt-1">
                      The Radio
                    </h3>
                  </div>
                </v-col>
              </transition>
              <v-col cols="12">
                <swiper ref="mySwiper" :options="swiperMenuOption" @click-slide="selectGenre">
                  <swiper-slide style="flex-shrink:1;">
                    <v-btn color="primary" outlined small>All</v-btn>
                  </swiper-slide>
                  <swiper-slide style="flex-shrink:1;">
                    <v-btn color="primary" outlined small>Liked</v-btn>
                  </swiper-slide>
                  <swiper-slide style="flex-shrink:1;"
                      v-for="group in content.content"
                      :key="group.id"
                  >
                    <v-btn color="primary" outlined small>{{group.title}}</v-btn>
                  </swiper-slide>
                </swiper>
              </v-col>
            </v-row>
          </v-col>
          <transition name="fade" appear>
            <v-col
                v-if="selectedRadio"
                align="center"
                id="AudioPlayerWrapper"
                class="py-2"
                cols="12"
            >
              <transition name="fade">
                <div>
                  <v-img
                      class="mt-2"
                      contain
                      max-width="70%"
                      max-height="100"
                      min-height="100"
                      :src="selectedRadio.imageSrc?selectedRadio.imageSrc:require('@/assets/none.png')"
                  ></v-img>
                </div>
              </transition>
              <h2 v-if="selectedRadio.title" class="text-center mt-3">
                {{ selectedRadio.title }}
              </h2>

              <p v-if="selectedRadio.description" v-html="selectedRadio.description" class="text-center mt-4">
              </p>

              <p v-if="selectedRadio.website" class="text-center">
                <a :href="selectedRadio.website" target="_blank">Website</a>
              </p>
              <v-footer class="pa-0" style="position: sticky;bottom:0;">
                <v-row
                    align="center"
                    class="d-flex pt-2"
                    id="controllerWrapper"
                    justify="center"
                >
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      xs="12"
                      class="pt-0 d-flex justify-center"
                  >
                    <!-- Play/Stop Controlls -->
                    <v-col cols="2" align="end">
                      <v-btn
                          fab
                          outlined
                          small
                          color="black"
                          @click="isRadioPlaying(selectedRadio)"
                      >
                        <v-icon dark v-if="!selectedRadio.playing" style="margin:0;">
                          mdi-play
                        </v-icon>
                        <v-icon dark v-else style="margin:0;">
                          mdi-stop
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <!-- Volume Slider -->
                    <v-col cols="6">
                      <v-slider
                          class="mt-1"
                          :color="!radioMuted ? 'black' : 'black'"
                          thumb-color="black"
                          :track-color="!radioMuted ? 'grey darken-1' : 'red darken-4'"
                          max="1.0"
                          step="0.04"
                          v-model="volume"
                          @change="volumeController()"
                          @click:prepend="isRadioMuted()"
                      ></v-slider>
                    </v-col>

                    <v-col cols="2">
                      <v-btn
                          icon
                          small
                          color="black"
                          @click="isRadioMuted()"
                      >
                        <v-icon dark v-if="radioMuted">
                          mdi-volume-variant-off
                        </v-icon>
                        <v-icon dark v-if="!radioMuted">
                          mdi-volume-source
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-footer>
            </v-col>
          </transition>
          <v-col style="padding: 0">
            <transition name="fade" appear>
              <div id="tableWrapper" class="section">
                <div v-for="(station,index) in selectedFilterGenre" :key="station.id+'_'+index">
                  <v-row>
                    <v-col>
                      <v-simple-table dark class="white">
                        <template>
                          <tbody>
                          <tr
                              :class="
                                station.playing === true ? 'PlayingStation' : ''
                              "
                          >
                            <td>
                              <v-btn
                                  icon x-small
                                  color="black"
                                  @click="
                                    isRadioPlaying(station)
                                  "
                              >
                                <v-icon dark v-if="!station.playing">
                                  mdi-play
                                </v-icon>
                                <v-icon dark v-if="station.playing">
                                  mdi-pause
                                </v-icon>
                              </v-btn>
                            </td>
                            <td
                                @click="
                                  isRadioPlaying(station)
                                "
                            >
                              <v-img
                                  contain
                                  max-width="50"
                                  max-height="50"
                                  height="50"
                                  width="50"
                                  :src="station.imageSrc"
                                  @error="
                                    station.imageSrc = require('@/assets/placeholder.jpeg')
                                  "
                              >
                              </v-img>
                            </td>
                            <td
                                @click="
                                  isRadioPlaying(station)
                                "
                            >
                              <h3>{{ station.title }}</h3>
                            </td>
                            <td>
                              <v-btn icon  x-small>
                                <v-icon
                                    v-if="likedStations.indexOf(station) >= 0"
                                    dark
                                    @click="unlikeStation(station)"
                                    style="color: red"
                                >
                                  mdi-heart
                                </v-icon>
                                <v-icon v-else @click="likeStation(station)" dark style="color: #000">
                                  mdi-heart-outline
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </transition>
          </v-col>
        </v-row>
        <!-- RADIO CONTROLS -->
      </v-col>
    </transition>
  </div>
</template>
<script>
import { Howl, Howler } from "howler";
import {mapMutations,mapState} from "vuex"
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);
import {Swiper,SwiperSlide} from "vue-awesome-swiper";
export default {
  name: "typeRadioPreview",
  props:['content'],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperMenuOption: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      likedStationsObj: {},
      stationData: [],
      stationDataIndex: null,
      arrayID: null,
      radioStarted: false,
      radioMuted: false,
      radioPaused: true,
      volume: this.$store.state.radio.volume,
      selectedGenre: "All",
      analyser: null,
      gainNode: null,
      drawTimer: null,
      frequency: [],
      ffrequency: null,
      audio: null,
      sourceAudio: null,
      errorMessage:"",
    };
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  computed: {
    ...mapState(['landing']),
    ...mapState('radio',['selectedRadio','likedStations','soundID','sound']),
    selectedFilterGenre: function() {
      if (this.selectedGenre === "All") {
        return this.content.content.reduce((acc,item) => {
          for(let i = 0;i<item.stations.length;i++){
            acc.push(item.stations[i]);
          }
          return acc;
        },[]);
      }
      if (this.selectedGenre === "Liked") {
        return this.likedStations;
      }
      if(Number.isInteger(this.selectedGenre)){
        return this.content.content.reduce((acc,item) => {
          if(item.id === this.selectedGenre){
            for(let i = 0;i<item.stations.length;i++){
              item.stations[i].group_id = item.id
              acc.push(item.stations[i]);
            }
          }
          return acc;
        },[]);
      }
      return this.content.content.reduce((acc,item) => {
        for(let i = 0;i<item.stations.length;i++){
          item.stations[i].group_id = item.id
          acc.push(item.stations[i]);
        }
        return acc;
      },[]);
    },
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
  },
  methods: {
    ...mapMutations('radio',['saveLikedStation','setSelectedStation','removeLikedStation','volumeSlider','setSoundID','setSound']),
    playAudio() {
      this.setSoundID(this.sound.play());
    },
    startRadio(station) {
      this.radioStarted = true;
      station.playing = true;
      this.setSound(new Howl({
        src: station.src,
        html5: true,
        volume: this.volume,
      }));
      Howler.masterGain.gain.value = this.volume;
      if (this.radioMuted) {
        this.muteRadioOnStart();
      }else{
        this.loading = false;
      }
    },
    muteRadioOnStart() {
      this.sound.fade(this.volume, 0.0, 0);
      this.loading = false;
    },
    isRadioPlaying(station) {
      if(!station.src){
        return;
      }
      this.loading = true;
      if (station.playing === false && this.radioStarted === false) {
        this.startRadio(station);
        this.playAudio();
        this.radioPaused = false;
      }
      else if (
          station.playing === false &&
          this.radioStarted === true
      ) {
        this.stopRadio(this.selectedRadio);
        this.startRadio(station);
        this.playAudio();
      } else {
        this.stopRadio(this.selectedRadio);
        this.radioPaused = true;
      }
      const payload = {
        groupID:station.group_id,
        station:station
      }
      this.setSelectedStation(payload);
    },
    stopRadio(station) {
      station.playing = false;
      this.radioStarted = false;
      this.sound.unload();
    },
    likeStation(station){
      this.saveLikedStation(station);
    },
    unlikeStation(station){
      this.removeLikedStation(station);
    },
    selectGenre(genre){
      if(genre === 0){
        this.selectedGenre = 'All';
      }
      if(genre === 1){
        this.selectedGenre = 'Liked';
      }
      if(genre > 1){
        this.selectedGenre = genre-2;
      }
    },
    keyDownHandler(e) {
      if (e.keyCode === 32) {
        this.isRadioMuted();
      }
    },
    isRadioMuted() {
      if (!this.radioMuted) {
        this.muteRadio();
      } else {
        this.unmuteRadio();
      }
    },
    muteRadio() {
      if (!this.radioStarted) {
        this.radioMuted = true
      } else {
        this.radioMuted = true
        this.sound.fade(this.volume, 0.0, 1200);
      }
    },
    unmuteRadio() {
      if (!this.radioStarted) {
        this.radioMuted = false;
      } else {
        this.radioMuted = false;
        this.sound.fade(0.0, this.volume, 1200);
      }
    },
    volumeController() {
      this.volumeSlider(this.volume);
      if (!this.radioStarted) {
        return;
      }
      if (!this.radioMuted) {
        this.sound.volume(this.volume);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  list-style-type: none;
}
#information {
  align-items: center;
}
#AudioPlayerWrapper {
}
h3,
h1,
h2,
p {
  color: #000;
  cursor: default;
}
p {
  color: #000;
  font-weight: bold;
}
tr:hover {
}
tr {
}
#tableWrapper {
  overflow: auto;
  width: 100%;
  padding: 15px;
  overflow-x: hidden;
}
.PlayingStation {
}
.PlayingStation:hover {
}
.section::-webkit-scrollbar {
  width: 20px;
}
.section::-webkit-scrollbar-track {
}
* ::-webkit-scrollbar {
  display: block !important;
}
a {
  text-decoration: none;
  font-size: 0.8em;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.2s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 1.2s ease-in-out;
}
.fade-enter-active {
  transition: opacity 1.2s ease-in-out;
}
.fade-enter-to {
  transition: opacity 1.2s ease-in-out;
}
</style>